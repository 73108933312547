import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FooterService } from '../global/footer/footer.service';
import { HeaderService } from '../global/header/header.service';
import { MessageService } from '../global/message/message.service';
import { MagentoService } from '../magento.service';
import { SessionService } from '../session.service';
import * as jQuery from 'jquery';
import { CandyService } from '../candy.service';
import { UtilService } from '../util.service';
import { HttpClient } from '@angular/common/http';
import { Title, Meta } from '@angular/platform-browser';

declare let smartlook;

@Component({
  selector: 'app-b2b-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginUsername: string = null;
  loginPassword: string = null;
  showUserDNE: Boolean = false;
  errorMessage: string = null;
  showPasswordForgottenPopup: boolean = false;
  showPasswordResetPopup: boolean = false;
  showRegister: boolean = false;

  email: String = null ;
  companyName: String = null;
  tradingName: String = null;
  countryRegistered: any = 79;
  EORINumber: String = "";
  regNumber: String = null;
  vatNumber: String = null;
  directorNames: String = null;
  contactName: String = null;
  contactPhone: String = null;

  agreeTerms: boolean = false;
  agreePromotions: boolean = false;

  searchQuery: String = null;
	addressLine1: String = null;
	addressLine2: String = null;
	addressTown: String = null;
	addressPostcode: string = null;
  addressRegion: String = null;
	addressResults: Array<{ "addressLine2": string, "addressTown": string, "addressPostcode": string }> = [];
  autofill: boolean = false;

  formError: boolean = false;
  formErrorMessage: String = null;

  constructor(private magento: MagentoService, private router: Router, private header: HeaderService, private footerService: FooterService, private message: MessageService, private session: SessionService, private candy: CandyService, private util: UtilService, private route: ActivatedRoute, private http: HttpClient, private title: Title, private meta: Meta) {
    this.header.setShowHeader(false);
    this.footerService.setShowFooter(false);
  }

  ngOnInit() {
    if(this.session.isLoggedIn()){
      this.router.navigateByUrl('/');
    }

    if(this.router.url === '/register') {
      this.showRegister = true;
    }

    this.title.setTitle('Commercial UK Dealer Portal | Ingram Micro Lifecycle');
		this.meta.addTag({name: 'description', content: 'Register/login to a commercial account today to access the best prices for technology devices. 3000+ available every day. Warranties supplied as standard.'});

    // if re-directed from password reset or new password made, show a message
    this.route.queryParams.subscribe(params => {
        if (Object.keys(params)[0] == 'pw-made-token') {
          this.message.showMessage('Your password has been saved successfully!', 'success')
        }
        if (Object.keys(params)[0] == 'pw-reset-token') {
          this.message.showMessage('Your password has been reset successfully!', 'success')
        }
    })
  }

  processLogin(){
    this.header.setShowLoader(true);

    this.candy.createRequest('POST', `api/login?email=${this.loginUsername}&password=${this.loginPassword}`).subscribe(response => {
      if(response){
            this.util.setCookie('customer_token', response['access_token'], 1); //4 = 4 hours to match magento's token timeout
            this.checkBasket(); // check if logged in user has basket, if so, add it to local storage
            this.session.getCategories();
            smartlook('identify', this.loginUsername)
            this.router.navigateByUrl('/');
          }
          this.header.setShowLoader(false);
      }, error => {
        this.showUserDNE = true;
        if(error['status'] === 503) {
          this.message.showMessage('Prices are currently being updated. Please try again later.', 'error');
        } else {
          this.message.showMessage(error.error.message, 'error');
        }
        this.header.setShowLoader(false);
    })
  }

  checkBasket(){
    this.candy.createRequest('GET', 'api/v1/baskets/current').subscribe(response=>{
      var basketID = response['data']['id'];
      if (basketID) {
        var itemCount = response['data']['lines']['data'].length;
        localStorage.setItem('cart', JSON.stringify({basket_id: basketID, item_count: itemCount}));
      }
    })
  }

  selectAddress(addressLine1, addressLine2, addressTown, addressPostcode, addressRegion) {
		this.addressLine1 = addressLine1;
		this.addressLine2 = addressLine2;
		this.addressTown = addressTown;
		this.addressPostcode = addressPostcode;
    this.addressRegion = addressRegion;
		this.addressResults = [];
		this.autofill = true;
	}

  
  setCountryRegistered(country) {
    // 79 is code for UK
    this.countryRegistered = 79;
  }

  getAddresses() {
		this.http.get('https://api.ideal-postcodes.co.uk/v1/postcodes/' + this.searchQuery + '/?api_key=ak_jwgh8ozzaKXDP2OQgKxvF7rjQdulN').subscribe(response => {
			this.addressResults = response['result'];
			jQuery('.address-finder-results').css('border-bottom-width', '1px');
		});
	}

  register() {
    this.formError = false;

    if (!this.companyName || !this.tradingName || !this.countryRegistered || !this.regNumber || !this.vatNumber || !this.email || !this.directorNames || !this.contactName || !this.contactPhone ) {
      this.formError = true;
      this.message.showMessage('Please ensure you have filled in all fields', 'error');
    }

    if (!this.contactName.includes(' ') ) {
      this.formError = true;
      this.message.showMessage('Please ensure a full name is used for contact name', 'error');
    }

    if(!this.formError){
      this.header.setShowLoader(true);

      var promotionsAgreed;
      this.agreePromotions ? promotionsAgreed = 'Yes' : promotionsAgreed = 'No';

      var data = {
                  'data' : {
                    'company': this.companyName,
                    "trading_name": this.tradingName,
                    'country': this.countryRegistered,
                    'company_registration_number': this.regNumber,
                    'company_vat_number': this.vatNumber,
                    "company_eori_number": this.EORINumber,
                    'email_address': this.email,
                    "director_names": this.directorNames,
                    "name" : this.contactName,
                    "firstname": this.contactName,
                    "lastname": this.contactName,
                    'phonenumber': this.contactPhone,
                    'address_line_1': this.addressLine1,
                    'address_line_2': this.addressLine2,
                    'postcode': this.addressPostcode,
                    'city': this.addressTown
                  }

                }

                var candydata = {
                  'company_name': this.companyName,
                  "trading_name": this.tradingName,
                  'country': this.countryRegistered,
                  'registration_number': this.regNumber,
                  'vat_number': this.vatNumber,
                  "eori_number": this.EORINumber,
                  'email': this.email,
                  "directors": this.directorNames,
                  "contact_name" : this.contactName,
                  'contact_phone': this.contactPhone,
                  'delivery_postcode': this.addressPostcode,
                  'delivery_region' : this.addressRegion,
                  'delivery_city': this.addressTown,
                  'delivery_address': this.addressLine1,
                  'delivery_address_two': this.addressLine2,
                  'billing_postcode': this.addressPostcode,
                  'billing_region' : this.addressRegion,
                  'billing_city': this.addressTown,
                  'billing_address': this.addressLine1,
                  'billing_address_two': this.addressLine2,
                }


      this.candy.createRequest('POST','api/v1/registration',candydata).subscribe(response => {
        console.warn(response)
        if(response['message'] == 'OK'){
          this.message.showMessage('Your application has been sent, thank you.', 'success');
          this.showRegister = false;
        } else {
          this.message.showMessage(response['error']['errors']['email'][0], 'error');
        }
        this.header.setShowLoader(false);
      }, response => {
        this.header.setShowLoader(false);
        this.message.showMessage(response['error']['errors']['email'][0], 'error');
      });
    }
  }

}


