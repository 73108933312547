import { THIS_EXPR, ThrowStmt } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';

import * as jQuery from 'jquery';
import { param } from 'jquery';
import { CandyService } from '../candy.service';
import { HeaderService } from '../global/header/header.service';
import { MessageComponent } from '../global/message/message.component';
import { MessageService } from '../global/message/message.service';
import { MagentoService } from '../magento.service';
import { UtilService } from '../util.service';

@Component({
	selector: 'app-cart',
	templateUrl: './cart.component.html',
	styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {


	cartContents: Array<any> = [];
	totalCheckout: number = null;
	isLoggedIn: Boolean = false;
	loggedInUser: Object = null;

	validPromo: Boolean = false;
	promoValue: number = 0;
	promoCode: String = null;
	verification: String = "";
  quoteId: number = 0;
	showQuotePopup: boolean = false;
  showPromoPopup: Boolean = false;
	fmiRemoved = false;

  popupValidationMessage: String = "";
	validationMessage: String = "";

	isBusinessRecycle: boolean = true;
	businessBaseValue: number = 0;
	businessPromoValueTotal: number =  0;
	removedAccounts: boolean;
	showValidationAccounts: boolean = false;
	basketID: any;

  products: Array<any> = [];
  product_count: Number = 0;
  total: number = 0;
  sub_total: number = 0;
  delivery: number = 0;
  discount: number = 0;
  showEmptyCart: Boolean = false;
  discount_code: String = null;
  vat: number = 0;

  showMOQMessage: boolean = false;

  moqQty: number = 0;

  token: String = null;

  constructor(
    private magento: MagentoService,
    private router: Router,
    private headerService: HeaderService,
    private util: UtilService,
    private message: MessageService,
    private candy: CandyService,
    private messageService: MessageService
    ) { }

  ngOnInit() {
    this.getCart();
  }

  getCart(csvProducts = null) {
    var cart = JSON.parse(localStorage.getItem('cart'));
    if (cart) {
      var basket_id = cart['basket_id'];
    }

    var token = null;
    var url = null;

    if(this.util.getCookie('customer_token')){
      this.token = this.util.getCookie('customer_token');
    }

    this.headerService.setShowLoader(true);

    // if(basket_id){

      if(this.token){
        url = 'api/v1/baskets/current';
      }
      // GC API

      this.candy.createRequest('GET', url).subscribe(response => {
        if(response){
          if(response['data']['id'] !== '') {
            this.sub_total = response['data']['sub_total'];
            this.discount = response['data']['discount_total'];
            // this.delivery = response['shipping_amount'];
            // this.vat = response['base_tax_amount'] > 0 && response['grand_total'] > 5000 ? 0 : response['base_tax_amount'] - response['base_shipping_tax_amount'];
            this.vat = response['data']['tax_total'];
            // remove delivery tax from being shown at this point
            // this.total = response['base_subtotal_with_discount'] > 5000 ? response['base_subtotal_with_discount'] : response['base_subtotal_with_discount'] + response['base_tax_amount'] - response['base_shipping_tax_amount'];
            this.total = response['data']['total'];

            // get products, store in array

            this.products = response['data']['lines']['data'];

            for(var product of this.products) {
              product.target_cost = null
              product.new_quantity = product.quantity;
            }

            var allProductsCellular = false; // Flag to check if all products are cellular

            this.products.forEach((product) => {
              product['img_url'] = 'loading';
              this.candy.getProductImagesWithCustomDetails(product['variant']['data']['product']['data']['model-id']).then((res) => {
                product['img_url'] = res['image'];
                product['custom_details'] = res['custom_details'];
                // Check if all products are cellular
                for (const product of this.products) {
                  if (
                    product['custom_details'] !== null &&
                    product['custom_details'] !== undefined && // Add this check
                    product['custom_details'].hasOwnProperty('is_cellular') &&
                    product['custom_details']['is_cellular']
                  ) {
                    allProductsCellular = true;
                    break;
                  }
                }
              }).catch(error => {
                console.log(error);
              });
            });

            this.product_count = this.products.length;
            this.basketID = response['data']['id']
            var cart = {
              basket_id: this.basketID,
              item_count:this.product_count
            }

            localStorage.setItem('cart', JSON.stringify(cart));

            var taxType ='';

            if (this.products[0]) taxType = this.products[0]['variant']['data']['tax']['data']['name'];

            this.candy.createRequest('GET', 'api/v1/quotes/basket-price?basket_id=' + this.basketID).subscribe(response => {
              if (response) {
                this.sub_total = 0;
                for (var i = 0; i < response.length; i++) {
                  this.products[i].unit_price = parseFloat(response[i].total) / response[i].quantity;
                  this.products[i].variant_id = response[i].product_variant_id;
                  this.sub_total += parseFloat(response[i].total);
                  this.total = this.sub_total;
                }

                this.vat = 0;

                if (allProductsCellular && this.total >= 5000 && taxType === 'Standard') {
                  this.vat = 0;
                } else {
                  if (this.products[0]) { this.products[0].tax ? this.vat = this.total * 0.2 : this.vat = 0; }
                  if (this.total >= 5000) { this.vat = 0; } // remove tax if value minimum 5000, only 2 for delivery vat
                  if (this.total >= 5000 && taxType === 'Standard') { this.vat = 0; } // remove tax if value minimum 5000 & is standard vat
                }
                this.total += this.vat;
                this.headerService.setShowLoader(false);
              }
            }, error => {
              this.headerService.setShowLoader(false);
            });

            //apply target prices from csv to newly created cart, only if it exists
            if(csvProducts) {
              for(let i = 0; i < this.products.length; i++) {
                if(csvProducts[i]['Target Price'] && csvProducts[i]['Target Price'] !== '') this.products[i]['target_cost'] = csvProducts[i]['Target Price']
              }
            }
          } else {
            // user does not have a basket, so let's make a new one for them and assign it
            this.candy.createRequest("POST", "api/v1/baskets").subscribe(response=> {
              var newBasketID = response['data']['id'];
              this.candy.assignCartToUser(newBasketID);
              this.basketID = newBasketID;
              localStorage.setItem('cart', JSON.stringify({basket_id: newBasketID, item_count: 0}));
              this.headerService.setShowLoader(false);
            })
          }
        } else {
          this.message.showMessage('There was an issue loading your basket', 'error');
          this.headerService.setShowLoader(false);
        }
      }, error => {
        this.headerService.setShowLoader(false);
        if(error['status'] === 503) {
          this.message.showMessage('Prices are currently being updated. Please try again later.', 'error');
          this.candy.customerLogout();
        } else {
          this.message.showMessage('There was an issue loading your basket', 'error');
        }
        this.headerService.setShowLoader(false);
      });

    // } else {
    //   this.showEmptyCart = true;
    //   this.headerService.setShowLoader(false);
    // }
  }

  getProductOptions(skus) {
    var data = {
      'data': skus
    }
    this.magento.createRequest('POST', 'V1/broadlanddigital/product/get-custom-attribute-values', data).subscribe(response => {
      var productOptions = response;
      productOptions.forEach(cartItem => {
        this.products.forEach(device => {
          if (Object.keys(cartItem)[0] == device.sku) {
            device['options'] = Object.values(cartItem)[0];
          }
        })
      });
      this.headerService.setShowLoader(false);
    })
  }

  getProductSkus () {
    var url = 'default/V1/carts/mine';

    // GC API
    // url = 'baskets/current';

    this.magento.createRequest('GET', url, null, this.token).subscribe(response => {
      this.products = response['items'];

      this.product_count = this.products.length;
         // get options to display on products

         var allSkus = [];

         this.products.forEach(device => {
           allSkus.push(device.sku)
         })

         this.getProductOptions(allSkus);

    })
  }

  updateProduct(product) {
    if(product.new_quantity === product.quantity) return;

    this.headerService.setShowLoader(true);
    // increase/decrease the stock as appropriate by using diff between old and new qty. Check stock levels before applying to current basket line
    var qtyDiff = product.new_quantity - product.quantity;

    product.new_price = product.unit_price * product.new_quantity;

    var data = {
      basket_id: this.basketID,
      variant_id: product['variant_id'],
      new_qty: product.new_quantity,
      price: product.unit_price
    }

    this.candy.createRequest('POST', 'api/v1/quotes/update-basket-prices', data).subscribe(response => {
    }, error => {
      this.headerService.setShowLoader(false);
    })

    if (qtyDiff < 0) {
      // qtyDiff < 0 means decrease item's quantity in cart so stock of item increases...
      // ONLY if stock change is succesful, change the current basket line quantity via then()
      this.candy.increaseStock(product['variant']['data']['sku'],qtyDiff * -1).then(res => {
        // check if no errors
        if (!res['error']) {
          this.candy.createRequest('PUT', 'api/v1/basket-lines/'+product.id+'?quantity='+product.new_quantity).subscribe(response => {
            product.quantity = product.new_quantity;
            this.getCart();
          }, error => {
            this.message.showMessage('There was an issue updating the product quantity', 'error');
          });
        } else {
          this.getCart();
        }
      });
    } else {
      this.candy.decreaseStock(product['variant']['data']['sku'],qtyDiff).then(res => {
        // check if no errors
        if (!res['error']) {
          this.candy.createRequest('PUT', 'api/v1/basket-lines/'+product.id+'?quantity='+product.new_quantity).subscribe(response => {
            product.quantity = product.new_quantity;
            this.getCart();
          }, error => {
            this.message.showMessage('There was an issue updating the product quantity', 'error');
          })
        } else {
          this.getCart();
        }
      });
    }

    setTimeout(()=>{
      this.headerService.setShowLoader(false);
    }, 3000)

  }

  updateData(){
    var cart = JSON.parse(localStorage.getItem('cart'));
    var basket_id = cart['basket_id'];

    var token = null;
    var url = null;

    if(this.util.getCookie('customer_token')){
      token = this.util.getCookie('customer_token');
    }

    if(basket_id){

      if(token){
        url = 'default/V1/carts/mine/totals';
      } else {
        url = 'default/V1/guest-carts/'+basket_id+'/totals';
      }


      // GC API
      // url = 'baskets/current';

      this.magento.createRequest('GET', url, null, token).subscribe(response => {
        if(response){
          this.sub_total = response['subtotal'];
          this.discount = response['discount'];
          this.delivery = response['shipping_amount'];
          this.total = response['grand_total'] > 5000 ? response['base_grand_total'] : response['grand_total'] ;

          this.magento.createRequest('GET', 'V1/broadlanddigital/cart/getproducts/'+basket_id).subscribe(response => {
            if(response){
              var resp = JSON.parse(response);
              if (resp.length == 0) {
                this.headerService.setShowCartDot(false);
              }
              this.products = resp['products'];
              if (this.products) {
                this.product_count = this.products.length;
              }
            } else {
              this.message.showMessage('There was an issue loading your basket', 'error');
            }
          }, response => {
            this.message.showMessage('There was an issue loading your basket', 'error');
          });
        } else {
          this.showEmptyCart = true;
          this.message.showMessage('There was an issue loading your basket', 'error');
        }
        this.headerService.setShowLoader(false);
      }, response => {
        this.headerService.setShowLoader(false);
        this.message.showMessage('There was an issue loading your basket', 'error');
      });

    } else {
      this.showEmptyCart = true;
      this.headerService.setShowLoader(false);
    }
  }

  getImageUrl(modelID) {
    return 'https://o2-api.devicetradein.co.uk/api/cache/images/model/' + modelID + '_1_pv.png'
  }

  editItem(product){
    this.moqQty = product.inner_qty;
    if (product.qty % this.moqQty != 0){
      this.showMOQMessage = true;
      return false;
    }

    this.magento.updateCart(product).then(response =>{
      this.message.showMessage(response, 'success');
      this.updateData();
    }, response => {
      this.message.showMessage(response, 'error');
    });
  }

  deleteItem(product, fetchCart = true){
    var basketLineID = product['id'];
    var qty = product['quantity'];
    var name = product['variant']['data']['product']['data']['manufacturer'] + ' ' + product['variant']['data']['product']['data']['name'];
    this.candy.removeFromCart(basketLineID, qty, name).then(response => {
      this.message.showMessage(response, 'success');
        // product removed from cart successfully, so let's make sure it's stock is increased accordingly
        this.candy.increaseStock(product['variant']['data']['sku'] , qty);
        if (fetchCart) this.getCart();
        // this.products = this.products.filter(currentProduct => currentProduct.id !== product.id);
        // if(this.products.length === 0) {
        //   this.total = 0;
        //   this.sub_total = 0;
        //   this.vat = 0;
        //   this.product_count = 0;
        // }
        // var taxType = 'Standard';
        // taxType = this.products[0]['variant']['data']['tax']['data']['name'];
        // this.sub_total = 0;
        // this.total = 0;
        // for(let product of this.products) {
        //   this.sub_total += parseFloat(product.line_total);
        // }
        // this.total = this.sub_total;
        // this.vat = 0;
        // if(this.products[0]) this.products[0].tax ? this.vat = this.total * 0.2 : this.vat = 0;
        // if (this.total>= 5000) this.vat = 0; // remove tax if value minimum 5000, only 2 for delivery vat
        // if (this.total >= 5000 && taxType === 'Standard') this.vat = 0; // remove tax if value minimum 5000 & is standard vat
        // this.total += this.vat;
      }, response => {
        this.message.showMessage(response, 'error');
    });
  }

  clearCart(fetchCart = true) {
    for (var product of this.products) {
      this.deleteItem(product, fetchCart);
    }
  }

  applyDiscountCode(){
    if(this.discount_code){
      this.headerService.setShowLoader(true);
      var cart = JSON.parse(localStorage.getItem('cart'));
      var basket_id = cart['basket_id'];


      // GC API
      this.candy.createRequest("GET", `discounts/${this.discount_code}`).subscribe(response => {

        // BELOW IS A GUESS UNTIL API READY
        if(response){
          this.message.showMessage('Your discount code has been accepted', 'success', true);
          this.headerService.setShowLoader(false);
        } else {
          this.message.showMessage('Please enter a discount code.', 'error', true);
          this.headerService.setShowLoader(false);
        }
      }, response => {
        this.message.showMessage(response['error']['message'], 'error', true);
        this.headerService.setShowLoader(false);
      });


      this.magento.createRequest('PUT', 'V1/carts/'+basket_id+'/coupons/'+this.discount_code).subscribe(response => {
        if(response){
          this.message.showMessage('Your discount code has been accepted', 'success', true);
          this.headerService.setShowLoader(false);
        }
      }, response => {
        this.message.showMessage(response['error']['message'], 'error', true);
        this.headerService.setShowLoader(false);
      });
    } else {
      this.message.showMessage('Please enter a discount code.', 'error', true);
      this.headerService.setShowLoader(false);
    }
  }

  saveQuote() {
    this.headerService.setShowLoader(true);

    var data = {
      target_costs: []
    }

    for (var product of this.products) {
      data.target_costs.push(product.target_cost);
    }

    this.candy.createRequest('POST', 'api/v1/quotes/create', data).subscribe(response => {
      this.headerService.setShowLoader(false);
      this.products = [];
      this.total = 0;
      this.sub_total = 0;
      this.cartContents = [];
      this.showQuotePopup = true;
      this.quoteId = response['id'];
      localStorage.removeItem('cart');
      this.headerService.setShowLoader(false);
    }, response => {
      this.message.showMessage(response['error']['message'], 'error', true);
      this.headerService.setShowLoader(false);
    });
  }

  upload(event) {
    this.headerService.setShowLoader(true)

    var products = [];

    // read CSV file
    let reader: FileReader = new FileReader();

    reader.readAsText(event.target.files[0]);

    // convert CSV file to JSON
    reader.onload = () => {

      try {
        let csv = <string>reader.result;
        csv = csv.replace(/['"]+/g, ''); //remove quote marks
        let allTextLines = csv.split(/\r|\n|\r/); //separate lines
  
        let headers = allTextLines[0].split(',');
  
        for (var line of allTextLines) {
  
          var product = {};
          for (let i = 0; i < headers.length; i++) {
  
            // split content based on comma
            var product_variables = line.split(',');
  
            //only update product if content exists
            if(headers[i] && headers[i] !== '') product[headers[i]] = product_variables[i];
  
          }
          if(product['QTY Required']) products.push(product);
        }
  
      } catch {
        this.messageService.showMessage('CSV file formatted incorrectly. Please ensure there is data in all columns', 'error', true);
        this.headerService.setShowLoader(false)
        reader = null;
        return;
      }

      //remove header items from products
      products.shift();
      

      if(!products[0]) {
        this.messageService.showMessage('CSV file formatted incorrectly. Please ensure there is data in all columns', 'error', true);
        this.headerService.setShowLoader(false)
        reader = null;
        return;
      }


      //disallow uploading items of different vat types
      var vatType = products[0]['VAT Type Name']
      for(var currentProduct of products) {
        if(vatType != currentProduct['VAT Type Name']) {
          this.messageService.showMessage('Cannot mix standard and marginal items in same basket', 'error', true);
          this.headerService.setShowLoader(false);
          return;
        }
      }

      var request = {
        data: []
      };
      var params = '';

      for(var item of products) {
        request.data.push(item['SKU']);
        var string = 'skus[]='+item['SKU']+'&';

        params += string;
      }

      if(this.products.length > 0) this.clearCart(false);

      this.candy.createRequest('POST', 'api/product/getProducVariantsBySKUs', request).subscribe(response => {
        var variantIDs = response['variant_ids'];
        var variants = [];

        for (var i = 0; i < products.length; i++) {
          variants.push(
            {
              "id": variantIDs[i],
              "quantity": products[i]['QTY Required']
            }
          )
        }

        var request = {
          "basket_id": this.basketID,
          "variants": variants
        }

        if(this.util.getCookie('customer_token')){
          // Use customer ID, which is their token when logging in, as the basket ID
          var token =  this.util.getCookie('customer_token');
        }

        var url = "api/v1/basket-lines";

        this.candy.createRequest('POST', url, request, token).subscribe(response => {

          var itemCount = response['data']['lines']['data'].length;

          localStorage.setItem('cart', JSON.stringify({basket_id: this.basketID, item_count: itemCount}));
          // product added to cart successfully, so let's make sure it's stock is reduced accordingly
          for(var i = 0; i < products.length; i++) {
            this.candy.decreaseStock(variantIDs[i],products[i]['QTY Required']);
          }
          this.messageService.showMessage('Your items have been added to your basket', 'success', true);
          this.headerService.setShowCartDot(true);
          this.getCart(products);
        }, error => {
          this.headerService.setShowMessage(error)
        });
      }, error => {
        console.warn(error);
      })
    }
  }

  addToCart(product) {
    this.headerService.setShowLoader(true);

    this.candy.addToCart(product).then(response => {
      this.headerService.setShowLoader(false);
      // prevent user adding if actual stock value in db is 0
      product['stock'] = product['stock'] - product['selected_qty'];

      //set target price to csv
    }, error => {
      this.headerService.setShowLoader(false);
      this.messageService.showMessage(error, 'error');
    }).catch(error=>{
      this.headerService.setShowLoader(false);
    });
  }

  reRoute() {
    this.router.navigateByUrl('/account/quotes');
  }
}
