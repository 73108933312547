import { KeyValue } from '@angular/common';
import { TransitiveCompileNgModuleMetadata } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { CandyService } from '../candy.service';
import { HeaderService } from '../global/header/header.service';
import { MessageService } from '../global/message/message.service';
import { MagentoService } from '../magento.service';
import { SessionService } from '../session.service';
import { UtilService } from '../util.service';
import { SortPipe } from '../global/pipes/sort.pipe';


@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})

export class ProductsComponent implements OnInit {
  products: Array<any> = [];

  productsWithFilters: Array<any> = [];

  brands: Array<any> = [];
  brandIDs: Array<any> = [];

  networks: Array<any> = [];
  networksCheckBoxes: Array<any> = [];
  networkIDs: Array<any> = [];

  grades: Array<any> = [];
  gradesCheckBoxes: Array<any> = [];
  gradesIDs: Array<any> = [];

  VATtypes: Array<any> = [];
  VATCheckBoxes: Array<any> = [];
  VATIDs: Array<any> = [];

  colours: Array<any> = [];
  coloursCheckBoxes: Array<any> = [];
  coloursIDs: Array<any> = [];

  hbs: Array<any> = [];
  hbsCheckBoxes: Array<any> = [];
  hbsIDs: Array<any> = [];

  memorySizes: Array<any> = [];
  memorySizesCheckBoxes: Array<any> = [];
  memorySizesIDs: Array<any> = [];

  isLoggedIn: boolean = false;

  currentDeviceType: string = 'Devices';

  currentUrl: string = '';

  simpleProductsWithOptions: Array<any> =[];

  selectedOptionsArray: Array<any> = [];
  selectedNetworkIndex: Array<number>  = [];
  selectedGradeIndex: Array<number>  = [];
  selectedMemoryIndex: Array<number>  = [];
  selectedColourIndex: Array<number>  = [];
  selectedVATIndex: Array<number>  = [];

  searchCriteria: String = null;
  searchResults: Array<any> = [];
  searchFinished: Boolean = false;
  account_url: String = '/login';
  categoryName: String = "";
  showLoader = null;
  showDropdown = false;
  showSearchLoader = null;
  showResults: Boolean = false;
  protected ngUnsubscribe: Subject<void> = new Subject<void>();

  currentPagination: number = 1;
  currentFilteredPagination: number = 1;

  currentSkus: Array<any> = [];
  currentSkusNoFilter: Array<any> = [];

  allPossibleConfigSkus: Array<any> = [];

  filteredOptions = {
    'brand': [],
    'grade': [],
    'vat_type': [],
    'color': [],
    'memory_size': [],
    'hb': []
  };

  showMobileFilters: boolean = false;

  showBrandFilter: boolean = true;
  showGradeFilter: boolean = false;
  showMemoryFilter: boolean = false;
  showColorFilter: boolean = false;
  showHbFilter: boolean = false;
  memorySizesReady: Array<any> = [];

  brandCheckBoxesMinOne: boolean = true;

  constructor(private magento: MagentoService, private candy: CandyService, private session: SessionService, private util: UtilService, private headerService: HeaderService, private route: ActivatedRoute, private messageService: MessageService, private router: Router, private message: MessageService) { }

  ngOnInit() {
    // show ALL config products without filters. Never show child products, paginated
    if (!this.route.snapshot.params.hasOwnProperty('brand') || this.route.snapshot.params['brand'] == 'brand')   {
      this.getConfigurableProducts();
    } else {
      this.headerService.setShowLoader(true);
    }

    if (this.session.isLoggedIn()) {
      this.isLoggedIn = true;
    }

    this.getFilters();
  }

  getFilters()  {
    this.headerService.setShowLoader(true);

    var parentId = parseInt(this.route.snapshot.params['parentId']);

    this.candy.createRequest('GET', 'api/modules/edit/categories').subscribe(response => {
      response.allCategories.forEach(cat => {
        if (cat.parent_id === parentId) {
          this.brands.push(cat);
        }

        if (cat.id === parentId) {
          this.currentDeviceType = cat.attribute_data.name.webstore.en;
        }
      });

      for (let brand of this.brands) {
        brand['selected'] = false;
      }

      // if entering view via brand query, check that brand and filter as appropriate
      if (this.route.snapshot.params.hasOwnProperty('brand') && this.route.snapshot.params['brand'] != 'brand')   {
        // find index of brand so checkbox can be set
        var param = this.route.snapshot.params['brand'];
        var foundBrand = this.brands.find(brand => brand['attribute_data']['name']['webstore'].en == param );
        var foundBrandIndex = this.brands.findIndex(brand => brand['attribute_data']['name']['webstore'].en == param );
        this.setBrand(foundBrand);

        // this.addBrandToFilter(this.route.snapshot.params['brand']);
      }

    }, error => {
      if (error['status'] === 503) {
        this.message.showMessage('Prices are currently being updated. Please try again later.', 'error');
        this.candy.customerLogout();
      } else {
        this.message.showMessage(error.error.message, 'error');
      }
      this.headerService.setShowLoader(false);
    });



    this.candy.createRequest('GET', 'api/search').subscribe(response => {
      // this.brands = Object.values(response[1]['manufacturer']);
      this.grades = response[1]['status'];
      this.colours = response[1]['colour'];
      this.memorySizes = response[1]['memory'];
      this.hbs = response[1]['hb'];

      // sort in order
      // this.brands = this.brands.sort((a,b) =>
      //   a.localeCompare(b)
      // )

      this.grades = this.grades.sort((a, b) =>
        a && b && a.localeCompare(b)
      );

      this.memorySizes = this.memorySizes.sort((a, b) =>
        a && b && a.replace('GB', '').replace('gb', '') - b.replace('GB', '').replace('gb', '')
      );

      this.colours = this.colours.sort((a, b) =>
        a && b && a.localeCompare(b)
      );

      // Sort hbs if they exist
      if (this.hbs && this.hbs.length > 0) {
        this.hbs = this.hbs.sort((a, b) =>
          a && b && a.localeCompare(b)
        );
      }

      for (let i = 0 ; i < this.grades.length; i++) {
        this.gradesCheckBoxes[i] = false;
      }

      for (let i = 0; i < this.colours.length; i++) {
        this.coloursCheckBoxes[i] = false;
      }

      for (let i = 0; i < this.memorySizes.length; i++) {
        this.memorySizesCheckBoxes[i] = false;
      }

      for (let i = 0; i < this.hbs.length; i++) {
        this.hbsCheckBoxes[i] = false;
      }

      // this.headerService.setShowLoader(false);
    }, error => {
      if (error['status'] === 503) {
        this.message.showMessage('Prices are currently being updated. Please try again later.', 'error');
        this.candy.customerLogout();
      } else {
        this.message.showMessage(error.error.message, 'error');
      }
      this.headerService.setShowLoader(false);
    })
  }

  setSelectedQty(product, e) {
    product['selected_qty'] = e.target.value;
  }

  clearSearch() {
    setTimeout(() => {
      this.showDropdown = false;
    }, 400);
  }

  processSearch() {
    this.ngUnsubscribe.next();

    if (this.searchCriteria.length >= 3){
      this.showDropdown = true;

      this.showSearchLoader = true;
      this.searchFinished = false;

      var request = 'api/search/?parents=1&q=';

      // GC API
      this.candy.createRequest('GET', request+ this.searchCriteria).subscribe(response => {
        if (response) {
          this.searchResults = Object.values(response[0]['data']);

          this.showResults = true;

          setTimeout(() => {
            this.showSearchLoader = false;
          }, 1000);

          this.searchFinished = true;
        } else {
          this.searchFinished = true;
          this.showSearchLoader = false;
        }
      });
    }
  }

  setBrand(brand) {
    this.headerService.setShowLoader(true);
    if (brand['selected'] === false) {
      brand['selected'] = true;
      this.filteredOptions['brand'].push(brand);
      this.applyFilters();
    } else {
      brand['selected'] = false;

      var check = this.filteredOptions['brand'].indexOf(brand);
      if (check !== -1) {
        this.filteredOptions['brand'].splice(check, 1);
      }
      this.applyFilters();
    }

    // ensure minimum 1 brand checkbox has been checked
    var countChecked = 0;
    for(let i = 0; i < this.brands.length; i++) {
      if (this.brands[i]['selected'] == true) {
        countChecked++;
      }
    }

    if (countChecked === 1) {
      this.brandCheckBoxesMinOne = true;
    } else {
      this.brandCheckBoxesMinOne = false;
    }
  }

  setGrade(grade, index) {
    this.headerService.setShowLoader(true);
    if (this.gradesCheckBoxes[index] == false) {
      this.gradesCheckBoxes[index] = true;
      this.filteredOptions['grade'].push(grade);
      this.applyFilters();
    } else {
      this.gradesCheckBoxes[index] = false;
      var check = this.filteredOptions['grade'].indexOf(grade);
      if (check !== -1) {
        this.filteredOptions['grade'].splice(check, 1);
      }
      this.applyFilters();
      this.headerService.setShowLoader(false);
    }
  }

  setColour(colour, index) {
    this.headerService.setShowLoader(true);
    if (this.coloursCheckBoxes[index] == false) {
      this.coloursCheckBoxes[index] = true;
      // Fix: Use colour directly rather than colour.value
      this.filteredOptions['color'].push(colour);
      this.applyFilters();
    } else {
      this.coloursCheckBoxes[index] = false;
      // Fix: Use colour directly rather than colour.value
      var check = this.filteredOptions['color'].indexOf(colour);
      if (check !== -1) {
        this.filteredOptions['color'].splice(check, 1);
      }
      this.applyFilters();
    }
  }

  setHb(hb, index) {
    this.headerService.setShowLoader(true);
    if (this.hbsCheckBoxes[index] == false) {
      this.hbsCheckBoxes[index] = true;
      // Fix: Use hb directly, not hb.value
      this.filteredOptions['hb'].push(hb);
      this.applyFilters();
    } else {
      this.hbsCheckBoxes[index] = false;
      // Fix: Use hb directly, not hb.value
      var check = this.filteredOptions['hb'].indexOf(hb);
      if (check !== -1) {
        this.filteredOptions['hb'].splice(check, 1);
      }
      this.applyFilters();
    }
  }

  setMemory(memory, index) {
    this.headerService.setShowLoader(true);
    // remove / from n/a since / makes endpoint think there is a new part to url
    if (memory == 'N/A') {
      memory = 'na';
    }
    if (this.memorySizesCheckBoxes[index] == false) {
      this.memorySizesCheckBoxes[index] = true;
      this.filteredOptions['memory_size'].push(memory.toLowerCase());
      this.applyFilters();
    } else {
      this.memorySizesCheckBoxes[index] = false;
      var check = this.filteredOptions['memory_size'].indexOf(memory.toLowerCase());
      if (check !== -1) {
        this.filteredOptions['memory_size'].splice(check, 1);
      }
      this.applyFilters();
    }
  }

  applyFilters() {
    this.headerService.setShowLoader(true);
    var request = 'api/search/?parents=1';
    // before 2nd march, only grabs variants
    var request2 = 'api/search/?';

    // Add brand filters
    for (let i = 0; i < this.filteredOptions['brand'].length; i++) {
      request += '&manufacturer[]=' + this.filteredOptions['brand'][i]['attribute_data']['name']['webstore'].en + '&category_id[]=' + this.filteredOptions['brand'][i]['id'];
      request2 += '&manufacturer[]=' + this.filteredOptions['brand'][i]['attribute_data']['name']['webstore'].en + '&category_id[]=' + this.filteredOptions['brand'][i]['id'];
    }

    // Add grade filters
    for (let i = 0; i < this.filteredOptions['grade'].length; i++) {
      request += '&grade[]=' +  this.filteredOptions['grade'][i];
      // Fix: Don't add manufacturer to request2 for non-brand filters
      request2 += '&grade[]=' +  this.filteredOptions['grade'][i];
    }

    // Add memory filters
    for (let i = 0; i < this.filteredOptions['memory_size'].length; i++) {
      request += '&memory[]=' +  this.filteredOptions['memory_size'][i];
      // Fix: Don't add manufacturer to request2 for non-brand filters
      request2 += '&memory[]=' +  this.filteredOptions['memory_size'][i];
    }

    // Add color filters - uncomment when ready to use
    for (let i = 0; i < this.filteredOptions['color'].length; i++) {
      request += '&colour[]=' +  this.filteredOptions['color'][i];
      // Fix: Don't add manufacturer to request2 for non-brand filters
      request2 += '&colour[]=' +  this.filteredOptions['color'][i];
    }

    // Add health battery filters
    for (let i = 0; i < this.filteredOptions['hb'].length; i++) {
      request += '&hb[]=' +  this.filteredOptions['hb'][i];
      // Fix: Don't add manufacturer to request2 for non-brand filters
      request2 += '&hb[]=' +  this.filteredOptions['hb'][i];
    }

    // encodeURIComponent
    this.candy.createRequest('GET', request).subscribe(response => {
      if (response) {
        this.products = Object.values(response[0]['data']);

        //move a+ option to beginning of array
        for(var product of this.products) {
          if (product.options && product.options.status) {
            var index = product.options.status.findIndex(status => status == 'Pristine A Plus');
            if(index > -1) {
              product.options.status.splice(0, 0, product.options.status.splice(index, 1)[0]);
            }

            index = product.options.status.findIndex(status => status == 'Pristine Sealed');
            if(index > -1) {
              product.options.status.splice(0, 0, product.options.status.splice(index, 1)[0]);
            }

            index = product.options.status.findIndex(status => status == 'Pristine Open Box');
            if(index > -1) {
              product.options.status.splice(0, 0, product.options.status.splice(index, 1)[0]);
            }
          }
        }

        // get images
        this.products.forEach((product) => {
          product['img_url'] = 'loading';
          this.candy.getProductImagesWithCustomDetails(product['model-id']).then((res) => {
            product['img_url'] = res['image'];
            product['custom_details'] = res['custom_details'];
          }).catch(error => {
            console.log(error);
          });
        });
        this.headerService.setShowLoader(false);
      }
    }, error => {
      console.warn(error);
      this.headerService.setShowLoader(false);
    });
  }

  toggleBrandFilter() {
    let brandFilter = document.getElementById('brand-filter-toggle');
    this.showBrandFilter = !this.showBrandFilter;

    if (this.showBrandFilter) {
      brandFilter.classList.remove('filters-close');
    } else {
      brandFilter.classList.add('filters-close');
    }
  }

  toggleGradeFilter() {
    let gradeFilter = document.getElementById('grade-filter-toggle');
    this.showGradeFilter = !this.showGradeFilter;

    if (this.showGradeFilter) {
      gradeFilter.classList.remove('filters-close');
    } else {
      gradeFilter.classList.add('filters-close');
    }
  }

  toggleMemoryFilter() {
    let memoryFilter = document.getElementById('memory-filter-toggle');
    this.showMemoryFilter = !this.showMemoryFilter;

    if (this.showMemoryFilter) {
      memoryFilter.classList.remove('filters-close');
    } else {
      memoryFilter.classList.add('filters-close');
    }
  }

  toggleColorFilter() {
    let colorFilter = document.getElementById('color-filter-toggle');
    this.showColorFilter = !this.showColorFilter;

    if (this.showColorFilter) {
      colorFilter.classList.remove('filters-close');
    } else {
      colorFilter.classList.add('filters-close');
    }
  }

  toggleHbFilter() {
    let hbFilter = document.getElementById('hb-filter-toggle');
    this.showHbFilter = !this.showHbFilter;

    if (this.showHbFilter) {
      hbFilter.classList.remove('filters-close');
    } else {
      hbFilter.classList.add('filters-close');
    }
  }

  resetFilters() {
    for (let i = 0 ; i < this.brands.length; i++) {
      this.brands[i]['selected'] = false;
    }

    for (let i = 0 ; i < this.grades.length; i++) {
      this.gradesCheckBoxes[i] = false;
    }

    for (let i = 0; i < this.colours.length; i++) {
      this.coloursCheckBoxes[i] = false;
    }

    for (let i = 0; i < this.memorySizes.length; i++) {
      this.memorySizesCheckBoxes[i] = false;
    }

    for (let i = 0; i < this.hbs.length; i++) {
      this.hbsCheckBoxes[i] = false;
    }

    this.filteredOptions = {
      'brand': [],
      'grade': [],
      'vat_type': [],
      'color': [],
      'memory_size': [],
      'hb': []
    };

    this.products = [];
    this.getConfigurableProducts();
  }

  openFilters() {
    let mobileNav = document.getElementById('mobile-filters');

    if (this.showMobileFilters ) {
      mobileNav.classList.remove('show-pop-up');
    } else {
      mobileNav.classList.add('show-pop-up');
    }

    this.showMobileFilters = !this.showMobileFilters;
  }

  getConfigurableProducts(page = 1) {
    this.headerService.setShowLoader(true);

    // scroll to point on screen then call function again
    var page = this.currentPagination;
    // increase pagination for next loop
    this.currentPagination++;
  }

  // Order by descending property key - needed to show memory options from smallest to largest when using | keyvalue pipe in ngFor
  keyDescOrder = (a: KeyValue<any, string>, b: KeyValue<any, string>): number => {
    return  a && b && a.key.replace('GB', '').replace('gb', '') - b.key.replace('GB', '').replace('gb', '');
  }

  setProductOption(product, grade?, memory?, colour?, vat?, hb?) {
    // Set loading state on the product object
    product.isLoading = true;

    // sku is used for endpoint to determine the child products
    const sku = product.sku;

    if (grade) {
      grade = grade.replaceAll(' ', '-');
      if (grade === 'Pristine-A-Plus') {
        grade = grade.toLowerCase();
      }
    }

    // assign selected values to current product
    // default argument passed for any option is undefined - this way, only one function can be used to carry out all product selections
    var currentGrade = product['selected_grade'] = grade ? grade : product['selected_grade'];
    var currentMemory = product['selected_memory'] = memory ? memory : product['selected_memory'];
    var currentColour = product['selected_colour'] = colour ? colour.replace(' ', '-') : product['selected_colour'];
    var currentVAT = product['selected_vat'] = vat ? vat : product['selected_vat'];
    var currentHB = product['selected_hb'] = hb ? hb : product['selected_hb'];

    // in situation where user goes backwards on a selection, restart if a child item cannot be made at that point...
    try {
      // Check if filters exist and are valid
      if (product['filters']) {
        // Safely check filters for status/grade
        if (grade && Array.isArray(product['filters']['status']) &&
          product['filters']['status'].indexOf('Grade ' + grade.replace('grade-', '').toUpperCase()) === -1) {
          currentMemory = product['selected_memory'] = undefined;
          currentColour = product['selected_colour'] = undefined;
          currentVAT = product['selected_vat'] = undefined;
          currentHB = product['selected_hb'] = undefined;
          delete product['price'];
          delete product['stock'];
        }
        // Safely check filters for memory
        else if (memory && Array.isArray(product['filters']['memory']) &&
          product['filters']['memory'].indexOf(memory) === -1) {
          currentGrade = product['selected_grade'] = undefined;
          currentColour = product['selected_colour'] = undefined;
          currentVAT = product['selected_vat'] = undefined;
          currentHB = product['selected_hb'] = undefined;
          delete product['price'];
          delete product['stock'];
        }
        // Safely check filters for colour
        else if (colour && Array.isArray(product['filters']['colour']) &&
          product['filters']['colour'].indexOf(colour) === -1) {
          currentMemory = product['selected_memory'] = undefined;
          currentGrade = product['selected_grade'] = undefined;
          currentVAT = product['selected_vat'] = undefined;
          currentHB = product['selected_hb'] = undefined;
          delete product['price'];
          delete product['stock'];
        }
        // Safely check filters for VAT
        else if (vat && Array.isArray(product['filters']['vat']) &&
          product['filters']['vat'].indexOf(vat.charAt(0).toUpperCase() + vat.substring(1)) === -1) {
          currentGrade = product['selected_grade'] = undefined;
          currentMemory = product['selected_memory'] = undefined;
          currentColour = product['selected_colour'] = undefined;
          currentHB = product['selected_hb'] = undefined;
          delete product['price'];
          delete product['stock'];
        }
        // Safely check filters for battery health
        else if (hb && Array.isArray(product['filters']['hb']) &&
          product['filters']['hb'].indexOf(hb) === -1) {
          currentGrade = product['selected_grade'] = undefined;
          currentMemory = product['selected_memory'] = undefined;
          currentColour = product['selected_colour'] = undefined;
          currentVAT = product['selected_vat'] = undefined;
          delete product['price'];
          delete product['stock'];
        }
      }
    } catch (error) {
      console.error('Error in filter validation:', error);
      // Set error state on product
      product.isLoading = false;
      product.hasError = true;
      return; // Exit early
    }

    // handle N/A memory option. The / is misread as a new URL so tweak that if found
    if (currentMemory) {
      currentMemory = currentMemory.replace('N/A', 'na');
    }

    // carefully build request string adding query parameters only if selected yet
    var request = `api/search/product/?&sku=${sku}${currentGrade ? '&grade[]=' + currentGrade : ''}${currentMemory ? '&memory[]=' + currentMemory : ''}${currentColour ? '&colour[]=' + currentColour : ''}${currentVAT ? '&vat[]=' + currentVAT : ''}${currentHB ? '&hb[]=' + currentHB : ''}`;

    this.candy.createRequest("GET", request).subscribe(
      response => {
        try {
          // Check if we have a valid response with filters
          if (!response || response.filters.length === 0) {
            throw new Error("Invalid response format - missing filters");
          }

          // Save current available variants to current product to filter relevant remaining options
          product['filters'] = response.filters;

          // Check if result exists and has values
          if (response.result && Array.isArray(response.result)) {
            product['variants'] = response.result;

            // If all 5 options selected and we have results, narrow down to one item
            if (product['selected_grade'] && product['selected_memory'] &&
              product['selected_colour'] && product['selected_vat'] &&
              product['selected_hb'] && response.result.length > 0) {

              // Make sure the first result has all required properties
              const selectedVariant = response.result[0];
              if (selectedVariant && selectedVariant.hasOwnProperty('stock') &&
                selectedVariant.hasOwnProperty('price')) {

                product['stock'] = selectedVariant['stock'];
                product['price'] = selectedVariant['price'];
                product['selected_variant'] = selectedVariant['hashid'] || null;
                product['selected_variant_sku'] = selectedVariant['sku'] || null;
              } else {
                throw new Error("Selected variant is missing required properties");
              }
            }
          } else {
            console.warn("No variant results found in the response");
            product['variants'] = [];
          }

          // Set loading state back to false after the API call completes
          product['isLoading'] = false;
        } catch (error) {
          console.error('Error processing product option response:', error);
          product['isLoading'] = false;
          product['hasError'] = true;
        }
      },
      error => {
        console.error('Error fetching product options:', error);
        // Set loading and error states
        product['isLoading'] = false;
        product['hasError'] = true;
      }
    );
  }

  handleResetProductCard(product) {
    // Mark the product as in reset state but don't show the global loader
    product['isLoading'] = true;

    // Only reset selection-related fields
    product['selected_grade'] = undefined;
    product['selected_memory'] = undefined;
    product['selected_colour'] = undefined;
    product['selected_vat'] = undefined;
    product['selected_hb'] = undefined;
    product['selected_qty'] = 1;
    product['hasError'] = false;

    // Remove only the dynamic data that results from selections
    delete product['price'];
    delete product['stock'];
    delete product['selected_variant'];
    delete product['selected_variant_sku'];

    // We want to keep the filters array but reset its content
    // This preserves the options structure while clearing any selection constraints
    if (product['filters']) {
      delete product['filters'];
    }

    if (product['variants']) {
      delete product['variants'];
    }

    // Clear loading state immediately since we're not making an API call
    setTimeout(() => {
      product['isLoading'] = false;
    }, 300); // Small timeout for visual feedback
  }

  resetOptions(product) {
    delete product['filters'];
    delete product['price'];
    delete product['stock'];
    product['selected_grade'] = '';
    product['selected_memory'] = '';
    product['selected_memory_ready'] = false;
    product['selected_colour'] = '';
    product['selected_vat'] = '';
    product['selected_hb'] = '';
    product['selected_qty'] = 1;
  }

  getProductUrl(product) {
    if (product.hasOwnProperty('sku')) {
      return `/products/${product['manufacturer']}/product/${product['sku']}`;
    } else {
      return false;
    }
  }

  getFilteredProductUrl(product) {
    if (product.hasOwnProperty('url_key')) {
      return '/products/brand/product/'+product['url_key'];
    }
  }

  increasePagination() {
    this.currentPagination++;
    // update filtered products OR update
    this.getConfigurableProducts();
  }

  increaseFilteredPagination() {
    this.currentFilteredPagination++;
    // update filtered products OR update
    this.getConfigurableProducts();
  }

  addToCart(product) {
    // set type_id to simple when send so can be added to cart
    product['type_id'] = "simple";

    product['selected_qty'] = product['selected_qty'] ? product['selected_qty'] : 1;

    this.headerService.setShowLoader(true);
    this.candy.addToCart(product, this.currentDeviceType).then(response => {
      this.headerService.setShowLoader(false);
      // prevent user adding if actual stock value in db is 0
      product['stock'] = product['stock'] - product['selected_qty'];
    }, error => {
      this.headerService.setShowLoader(false);
      this.messageService.showMessage(error, 'error');
    }).catch(error=>{
      this.headerService.setShowLoader(false);
    });
  }

  updateAndDownloadStock() {
    this.headerService.setShowLoader(true);
    this.candy.createRequest('GET-CSV', 'api/modules/export/products', {responseType: 'blob'}).subscribe(response => {
      this.headerService.setShowLoader(false);
      const blob = new Blob([response], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'exportCsv.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    }, error => {
      console.warn('Error during stock update and download:', error);
      this.headerService.setShowLoader(false);
    });
  }

  // Add this method to handle the setProductOptionEvent from product-card
  handleSetProductOption(options) {
    const { product, grade, memory, colour, vat, hb } = options;
    this.setProductOption(product, grade, memory, colour, vat, hb);
  }

// Add this method to handle the setSelectedQtyEvent from product-card
  handleSetSelectedQty(data) {
    const { product, event } = data;
    this.setSelectedQty(product, event);
  }
}
