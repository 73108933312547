import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent implements OnInit {
  @Input() product: any;
  @Input() isLoggedIn = false;
  @Input() currentDeviceType: string;
  @Output() addToCartEvent = new EventEmitter<any>();
  @Output() setProductOptionEvent = new EventEmitter<any>();
  @Output() setSelectedQtyEvent = new EventEmitter<any>();
  @Output() resetProductCardEvent = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  // New method to sort grades in a logical order
  getSortedGrades(grades: string[]): string[] {
    if (!grades || !Array.isArray(grades)) {
      return [];
    }

    // Function to get a numeric value for a grade (lower is better)
    const getGradeValue = (grade: string): number => {
      if (grade.includes('Sealed')) return 1;
      if (grade.includes('Open Box')) return 2;

      // Look for A+, A, B, C, etc.
      if (grade.includes('A+')) return 3;
      if (grade.includes('A')) return 4;
      if (grade.includes('B')) return 5;
      if (grade.includes('C')) return 6;
      if (grade.includes('D')) return 7;
      if (grade.includes('E')) return 8;
      if (grade.includes('F')) return 9;

      return 999; // Default value for unknown grades
    };

    // Sort the grades
    return [...grades].sort((a, b) => {
      return getGradeValue(a) - getGradeValue(b);
    });
  }

  addToCart(product) {
    this.addToCartEvent.emit(product);
  }

  setProductOption(product, grade?, memory?, colour?, vat?, hb?) {
    // If this is a brand that doesn't support battery health, set a default value
    if (hb === undefined && !this.shouldShowBatteryHealth(product) && product['options'] && product['options']['hb'] && product['options']['hb'].length > 0) {
      // Use the first available option as default (usually "No")
      hb = product['options']['hb'][0];
    }

    // First, clear any previous error state
    product['hasError'] = false;

    // Then emit the event with all values
    const options = { product, grade, memory, colour, vat, hb };
    this.setProductOptionEvent.emit(options);
  }

  setSelectedQty(product, event) {
    const data = { product, event };
    this.setSelectedQtyEvent.emit(data);
  }

  shouldShowBatteryHealth(product: any): boolean {
    if (product && product.option_data && product.option_data.hb) {
      const batteryOptions = product.options.hb;
      // Check if there is only one option and it is "No"
      if (batteryOptions.length === 1 && batteryOptions[0].toLowerCase() === 'no') {
        // Auto-select the only available option
        product.selected_hb = batteryOptions[0];
        return false;
      }
    }
    return true;
  }

  /**
   * Resets a product card that has entered an error state
   */
  resetProductCard(product) {
    // Clear error state
    product['hasError'] = false;

    // Clear filters and selected options
    delete product['filters'];
    delete product['price'];
    delete product['stock'];
    product['selected_grade'] = undefined;
    product['selected_memory'] = undefined;
    product['selected_colour'] = undefined;
    product['selected_vat'] = undefined;
    product['selected_hb'] = undefined;

    // Emit reset event to parent component
    this.resetProductCardEvent.emit(product);
  }
}
